export const DASHBOARD_PAGE_ACCOUNT_REDIRECT_TARGET = 'BmidHoldingListComponent.trigger';
export const DASHBOARD_PAGE_TEAM_BUY_LIST_ENABLED = 'BmidHoldingListComponent.teambuylist.enabled';
export const DASHBOARD_KEEP_BMID_LIST_OPEN_MONITOR_FLAGS = 'DASH.bmid.list.keepOpenOnClickMonitorFlags';

export const PMP_PAGE_APP_WIDGET_MODEL_PICKER_VISIBLE = 'WidgetModelPickerComponent.visible';
export const PMP_PAGE_APP_WIDGET_ACTIONS_VISIBLE = 'WidgetActionsComponent.visible';
export const PMP_PAGE_KEY_MONITOR_WIDGET_VISIBLE = 'pmp.keyFlagMonitorWidget.visible';
export const PMP_PAGE_NON_KEY_MONITOR_WIDGET_VISIBLE = 'pmp.nonkeyFlagMonitorWidget.visible';
export const PMP_PAGE_FACTOR_ANALYSIS_HIDDEN = 'pmp.factorAnalysis.hidden';
export const PMP_PAGE_FACTOR_ANALYSIS_REPORT_HIDDEN = 'pmp.factorAnalysisReport.hidden';
export const PMP_PAGE_TRADE_RATIONALE_ENABLED = 'pmp.tradeRationale.enabled';
export const PMP_PAGE_RISK_CATEGORY_VISIBLE = 'pmp.risk.category.visible';
export const PMP_PAGE_INVESTMENT_PROPOSAL_ENABLED = 'pmp.investmentProposal.enabled';

export const PMP_PAGE_TOTAL_RETURNS_VISIBLE = 'pmp.totalReturns.visible';

export const PMP_RISK_MODEL_SELECTION_ENABLED = 'pmp.riskModelSelection.enabled';
export const PMP_ALPHA_MODEL_SELECTION_ENABLED = 'pmp.alphaModelSelection.enabled';

export const PMP_IPR_REQUIRES_COMPLETED_QUESTIONNAIRE = "pmp.iprRequiresCompletedQuestionnaire";
export const PMP_IPR_REPORT_NAMES = 'pmp.iprReportNames';
export const PMP_EXPORT_DATA_FORMAT = 'pmp.export.data.format';
export const PMP_EXTERNAL_TRADE_ORDER_ENABLED = 'pmp.external.trade.order.enabled';
export const PMP_INVEST_DIVEST_ENABLED = 'pmp.investDivest.enabled';
export const PMP_TRADE_LIST_COLUMNS = 'pmp.trade.list.columns';
export const BULK_TRADE_LIST_COLUMNS = 'bulkTrade.trade.list.columns';
export const BULK_TRADE_ENABLED = 'bulkTrade.enabled';
export const BULK_TRADE_EXTERNAL_TRADE_ENABLED = 'bulkTrade.external.trade.order.enabled';
export const BULK_TRADE_RATIONAL_ENABLED = 'bulkTrade.tradeRationale.enabled';
export const BULK_TRADE_DISPLAY_BOTH_ENABLED = 'bulkTrade.display.both.buysell.enabled';
export const BULK_TRADE_PRICE_CURRENCY_OVERRIDE = 'bulkTrade.price.currency.override';
export const TRADE_ORDER_DIRECTION_ID_MAP = 'trade.order.directionId.map';
export const TRADE_ORDER_SAVE_ON_SERVER = 'trade.order.saveOnServer';
export const TRADE_ORDER_FUND_RAISE_AND_INVEST = 'trade.order.fund.raiseAndInvest';

export const CLIENT_HOME_PAGE_EDIT_CLIENT_ENABLED = 'clientHomePage.editClient.enabled';
export const CLIENT_HOME_PAGE_ADD_BMID_ENABLED = 'clientHomePage.addBmid.enabled';
export const CLIENT_HOME_PAGE_FILE_NOTES_ENABLED = 'clientHomePage.fileNotes.enabled';
export const CLIENT_HOME_PAGE_DISPLAY_SOURCE_COLUMN = 'clientHomePage.display.source.column';
export const CLIENT_HOME_PAGE_DISPLAY_OLD_SCENARIOS = 'clientHomePage.display.old.scenarios';
export const CLIENT_QUESTIONNAIRE_ENABLED = 'client.questionnaire.enabled';
export const CLIENT_PROFILE_PAGE_CONFIRM_LABEL = 'clientProfilePage.confirm.label';
export const CLIENT_PROFILE_PAGE_AUTO_CONFIRM_LABEL = 'clientProfilePage.autoConfirm.label';
export const CLIENT_PROFILE_PAGE_ADJUSTMENT_TYPES_LABEL_TAA = 'clientProfilePage.adjustment.types.label.TAA';
export const CLIENT_PROFILE_PAGE_ADJUSTMENT_TYPES_LABEL_SAA = 'clientProfilePage.adjustment.types.label.SAA';
// tslint:disable-next-line:max-line-length
export const CLIENT_PROFILE_PAGE_ADJUSTMENT_MODEL_PORTFOLIO_CATEGORY_ENABLED = 'clientProfilePage.adjustment.modelPortfolioCategory.enabled';
export const CLIENT_PROIFLE_PAGE_CLEAR_CONFIRMED_PROFILE_ENABLED = 'clientProfilePage.clearConfirmedProfile.enabled';
export const CLIENT_QUESTIONNAIRE_PRINT_ENABLED = 'clientQuestionnairePage.print.enabled';
export const CLIENT_QUESTIONNAIRE_BLANK_ENABLED = 'clientQuestionnairePage.blank.enabled';
export const CLIENT_PROFILE_ENABLED = 'client.profile.enabled';
export const DASH_NAVIGATION_ENABLED = 'dash.navigation.enabled';
export const CLIENT_NAVIGATION_ENABLED = 'client.navigation.enabled';
export const SEARCH_NAVIGATION_ENABLED = 'search.navigation.enabled';
export const CLIENT_KYC_ENABLED = 'client.kyc.enabled';
export const CLIENT_ANALYTIC_FACTOR_ENABLED = 'client.analytic.factor.enabled';
export const CLIENT_SEARCH_ACCOUNT_OWNER_MANAGER_ENABLED = 'client.search.accountOwner.accountManager.enabled';
export const CLIENT_ADD_ACCOUNT_ENABLED = 'client.addAccount.enabled';
export const CLIENT_GENERATE_ACCESS_TOKEN_ENABLED = 'client.generate.accessToken.enabled';
export const UI_LOCALE = 'ui.locale';
export const NEW_CLIENT_ENABLED = 'newClient.enabled';
export const NEW_CLIENT_ADD_BUTTON_LABEL = 'newClient.add.button.label';
export const MOVE_ACCOUNT_ENABLED = 'moveAccount.enabled';
export const MOVE_BMID_ENABLED = 'moveBmid.enabled';
export const PERFORMANCE_REPORT_ENABLED = 'performance.report.enabled';
export const DISPLAY_PADLOCK_FOR_LOCKED_ACCOUNT_ENABLED = 'display.padlock.enabled';
export const MONITOR_FIRM_ENABLED = 'monitor.firm.enabled';
export const PMP_PAGE_FILE_NOTES_ENABLED = 'portfolio.slwi.ipr.fileNotes.enabled';
export const ASSET_SEARCH_DEFAULT_BUYLIST = 'assetSearch.default.buyList';
export const PORTFOLIO_LOOKTHROUGH_ENABLED = 'portfolio.lookthrough.enabled';
export const ASSET_SEARCH_EPIC_ENABLED = 'assetSearch.epic.enabled';
export const ASSET_ANALYTICS_ENABLED = 'aap.enabled';
export const ESG_ENABLED = 'esg.enabled';
export const PORTFOLIO_PERFORMANCE_ENABLED = 'ppp.enabled';
export const KNOWN_EXCEPTIONS_HOLDING_EXCLUSION_ENABLED = 'knownExceptions.holdingExclusion.enabled';
export const BUYLIST_PRINT_EDITABLE_FORM_ENABLED = 'buylist.print.editable.form.enabled';

export const KEEP_FILTER_DRAWER_OPEN_IF_HAS_VALUES = 'keep.filters.drawer.open.if.has.values';

export const PPP_FIRST_RATE_URL = 'ppp.first.rate.url';
export const ESG_KEY_CHANGES_VISIBLE = 'esg.key.changes.visible';
export const ESG_PRODUCT_PREFERENCES_VISIBLE = 'esg.product.preferences.visible';
export const ESG_PREFERENCES_VISIBLE = 'esg.preferences.visible';
export const ESG_CO2_EXPOSURE_VISIBLE = 'esg.co2.exposure.visible';
export const ESG_CO2_EXPOSURE_LABEL = 'esg.co2.exposure.label';
export const ESG_CO2_CONTRIBUTIONS_LABEL = 'esg.co2.contributions.label';
export const ESG_CO2_CONTRIBUTIONS_FOOTER = 'esg.co2.contributions.footer';
export const ESG_IMPACT_REPORT_STOCKS_VISIBLE = 'esg.impact.report.stocks.visible';
export const ESG_KEY_METRICS_VISIBLE = 'esg.key.metrics.visible';
export const ESG_IMPACT_REPORT_PREFERENCES_VISIBLE = 'esg.impact.report.preferences.visible';
export const ESG_IMPACT_REPORT_EXPOSURE_HISTORY_ENABLED = 'esg.impact.report.exposure.history.enabled';
export const ESG_COPYRIGHT_TEXT = 'esg.copyright.text';
export const ESG_COPYRIGHT_TEXT_SHORT = 'esg.copyright.text.short';
export const REPORTS_DOWNLOAD_ENABLED = 'reports.download.enabled';
export const PMI_ENABLED = 'pmi.enabled';
export const QUESTIONNAIRE_EXPORT_AS_PDF_ENABLED = 'questionnaire.exportAsPdf.enabled';
export const KYC_EXPORT_AS_PDF_ENABLED = 'kyc.exportAsPdf.enabled';
export const ESG_EXPORT_AS_PDF_ENABLED = 'esg.exportAsPdf.enabled';

export const ESG_QUESTIONNAIRE_PAGE_ENABLED = 'esg.questionnaire.page.enabled';
export const ESG_QUESTIONNAIRE_PAGE_SEND_ENABLED = 'esg.questionnaire.page.send.enabled';
export const ESG_EARROW_PAGE_ENABLED = 'esg.earrow.page.enabled';
export const ESG_CONFLICT_REPORT_PAGE_ENABLED = 'esg.esgConflictReport.page.enabled';
export const ESG_ECO2_PAGE_ENABLED = 'esg.eco2.page.enabled';
export const ESG_OVERALL_IMPACT_REPORT_PAGE_ENABLED = 'esg.overallImpactReport.page.enabled';
export const ESG_ERQ_CONFLICT_REPORT_PAGE_ENABLED = 'esg.erqConflictReport.page.enabled';

export const PMP_ESG_FACTOR_PREFERENCE_CHART_VISIBLE = 'pmp.esgFactorPreferenceChart.visible';
export const PMP_ESG_CO2_CHART_VISIBLE = 'pmp.esgCo2Chart.visible';
export const PMP_ESG_THREE_PILLAR_CHART_VISIBLE = 'pmp.esgThreePillarChart.visible';
export const PMP_ESG_TOP_BAR_VISIBLE = 'pmp.esgTopBar.visible';

export const PMP_PAGE_PROFILE_WIDGET_VISIBLE = 'pmp.profile.widget.visible';
export const PMP_PAGE_QUICK_ISSUE_WIDGET_VISIBLE = 'pmp.quick.issue.widget.visible';
export const PMP_PAGE_ALERTS_WIDGET_VISIBLE = 'pmp.alerts.widget.visible';

export const PMP_PAGE_MONITOR_DIAL_WIDGET_VISIBLE = 'pmp.monitor.dial.widget.visible';
export const PMP_PAGE_CURRENT_VS_TARGET_WIDGET_VISIBLE = 'pmp.current.vs.target.widget.visible';
export const PMP_PAGE_PROPOSED_VS_TARGET_WIDGET_VISIBLE = 'pmp.proposed.vs.target.widget.visible';
export const PMP_PAGE_RISK_CONTRIBUTION_WIDGET_VISIBLE = 'pmp.risk.contribution.widget.visible';
export const PMP_PAGE_CASH_WIDGET_VISIBLE = 'pmp.cash.widget.visible';
export const PMP_PAGE_MODEL_PICKER_WIDGET_VISIBLE = 'pmp.model.picker.widget.visible';

export const MMP_TEMPLATE_TEAM_CLIENT_ENABLED = 'MODEL.template.teamClient.enabled';
export const MMP_TEMPLATE_EDIT_CLIENT_ENABLED = 'MODEL.template.editClient.enabled';

export const MMP_PMP_SAVE_UPDATE_ADMIN_MPS_ENABLED = 'MODEL.template.saveUpdate.adminMps.enabled';
export const MMP_PMP_SAVE_UPDATE_MODEL_APPROVER_REQUIRED_ENABLED = 'MODEL.template.saveUpdate.requireModelApprover.enabled';
export const SPM_MODEL_NAME_PREFIX = 'spm.model.name.prefix';
export const SPM_MODEL_NAME_FIELD_SEPARATOR = 'spm.model.name.field.separator';
export const SPM_MODEL_CLIENT_ID = 'spm.model.clientId';
export const SPM_MODEL_NAME_SUFFIX_DATE_FORMAT = 'spm.model.name.suffix.date.format';

export const MONITOR_REPORT_POLLING_PERIOD = 'monitor.report.polling.period';

export const MONITOR_REPORT_POLLING_RETRY_COUNT = 'monitor.report.polling.retry.count';
export const DEFAULT_LANDING_PAGE = 'default.landing.page';
export const PDF_PAGE_OPTIONS_ESG_OVERALL_IMPACT_REPORT = 'pdf.page.options.esg_overall_impact_report';

export const TCFD_DASHBOARD_PAGE_BENCHMARK_VISIBLE = 'tcfd.dashboard.page.benchmark.visible';
export const TCFD_DASHBOARD_PAGE_COMMENTARY_VISIBLE = 'tcfd.dashboard.page.commentary.visible';
export const TCFD_DASHBOARD_PAGE_ENABLED = 'tcfd.dashboard.page.enabled';
export const TCFD_DETAILED_PAGE_ENABLED = 'tcfd.detailed.page.enabled';
export const TCFD_HISTORY_PAGE_ENABLED = 'tcfd.history.page.enabled';
export const TCFD_ASSET_PAGE_ENABLED = 'tcfd.asset.page.enabled';
export const TCFD_ENTITY_PAGE_ENABLED = 'tcfd.entity.page.enabled';

export const EXCO_PAGE_ENABLED = 'exco.page.enabled';

export const P_A_HIDE_ACCOUNT_EXTERNAL_ID = 'accountExternalId.hidden';
