<div class="text-center">
    <h1>Oops! Something went wrong.</h1>
</div>
<br />

<div class="text-center">
    <span class="h2-font-size">We&rsquo;re sorry, but the page you&rsquo;re looking for doesn&rsquo;t exist.</span><br />
    <span class="h2-font-size">Here are some options to help you find your way:</span>

    <hr>

    <ul>
        <li><span class="h2-font-size">Check the URL and try again.</span></li>
        <li><span class="h2-font-size">Go back to the <a title="Dashboard" routerLink="/dash" rel="noreferrer noopener">Dashboard Page</a>.</span></li>
        <li><span class="h2-font-size">Use the <a title="Search" routerLink="/search" rel="noreferrer noopener">Search</a> to find what you need.</span></li>
        <li><span class="h2-font-size">Contact <a title="BitaSupport@corfinancialgroup.com" href="mailto:BitaSupport@corfinancialgroup.com" rel="noreferrer noopener">Support</a> if you need further assistance.</span></li>
    </ul>
</div>