import { AccessDeniedComponent } from './core/access-denied/access-denied.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { PasswordComponent } from './auth/password/password.component';
import { AuthenticatedGuard } from './auth/authenticated.guard';
import * as Paths from './core/routing/routing.const';
import { AuthorizationGuard } from './auth/authorization.guard';
import { Authority } from './core/entity/authority.enum';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { ErrorOccurredComponent } from './core/error-occurred/error-occurred.component';

@NgModule({
    imports: [
      RouterModule.forRoot([
    { path: '', redirectTo: Paths.LOG_IN_PAGE, pathMatch: 'full' },
    { path: Paths.DASHBOARD_PAGE, loadChildren: () => import('./dash/dash.module').then(m => m.DashModule),
        canActivate: [AuthenticatedGuard] },
    { path: Paths.NEW_CLIENT, loadChildren: () => import('./new-client/new-client.module').then(m => m.NewClientModule),
        canActivate: [AuthenticatedGuard, AuthorizationGuard],
        data: { authorities: [Authority.AUTHORITY_CLIENT_CREATE] } },
    { path: Paths.LOG_IN_PAGE, component: LoginComponent },
    { path: Paths.LOG_IN_OAUTH2_PAGE, component: LoginComponent },
    { path: Paths.PASSWORD_PAGE, component: PasswordComponent },
    { path: Paths.CLIENT_PAGE, loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
        canActivate: [AuthenticatedGuard] },
    { path: Paths.MONITOR_PAGE, loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule),
        canActivate: [AuthenticatedGuard, AuthorizationGuard],
        data: { authorities: [Authority.AUTHORITY_MONITOR] } },
    { path: Paths.ADMIN_PAGE, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthenticatedGuard, AuthorizationGuard],
        data: { authorities: [Authority.AUTHORITY_ADMIN_CLIENT_LIST_R, Authority.AUTHORITY_ADMIN_CLIENT_R,
                Authority.AUTHORITY_ADMIN_ENTITIES_R, Authority.AUTHORITY_ADMIN_GROUPS_R,
                Authority.AUTHORITY_ADMIN_USERS_R] } },
    { path: Paths.APPROVALS_PAGE, loadChildren: () => import('./approvals/approvals.module').then(m => m.ApprovalsModule),
        canActivate: [AuthenticatedGuard, AuthorizationGuard],
        data: { authorities: [Authority.AUTHORITY_APPROVAL] } },
    { path: Paths.SEARCH_PAGE, loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
        canActivate: [AuthenticatedGuard] },
    { path: Paths.MODELS_PAGE, loadChildren: () => import('./models/models.module').then(m => m.ModelsModule),
        canActivate: [AuthenticatedGuard, AuthorizationGuard],
        data: { authorities: [Authority.AUTHORITY_MODEL_MANAGEMENT_R] } },
    { path: Paths.BULK_PAGE, loadChildren: () => import('./bulk/bulk.module').then(m => m.BulkModule),
        canActivate: [AuthenticatedGuard, AuthorizationGuard],
        data: { authorities: [Authority.AUTHORITY_BULK_TRADE_R] } },
    { path: Paths.BUY_LIST, loadChildren: () => import('./team-buylist/team-buylist.module').then(m => m.TeamBuylistModule),
        canActivate: [AuthenticatedGuard, AuthorizationGuard],
        data: { authorities: [Authority.AUTHORITY_TEAM_BUY_LIST_R] }
    },
    { path: Paths.ACCESS_DENIED, component: AccessDeniedComponent },
    { path: Paths.PAGE_NOT_FOUND, component: PageNotFoundComponent },
    { path: Paths.ERROR, component: ErrorOccurredComponent },
    { path: '**', component: PageNotFoundComponent }
], {})
    ],
    exports: [
      RouterModule
    ]
})
export class AppRoutingModule { }

