export const LOG_IN_PAGE = 'signin';
export const LOG_IN_OAUTH2_PAGE = 'signin/oauth2';
export const DASHBOARD_PAGE = 'dash';
export const NEW_CLIENT = 'newClient';
export const PASSWORD_PAGE = 'password';
export const CLIENT_PAGE = 'client';
export const MONITOR_PAGE = 'monitor';
export const ADMIN_PAGE = 'admin';
export const APPROVALS_PAGE = 'approvals';
export const SEARCH_PAGE = 'search';
export const MODELS_PAGE = 'models';
export const BULK_PAGE = 'bulk';
export const ACCESS_DENIED = 'accessDenied';
export const PAGE_NOT_FOUND = '404';
export const BUY_LIST = 'buyList';
export const ERROR = 'error';
