import { Component } from '@angular/core';

@Component({
  selector: 'app-error-occurred',
  templateUrl: './error-occurred.component.html',
  styleUrls: ['./error-occurred.component.scss']
})
export class ErrorOccurredComponent {

}
